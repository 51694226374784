
.md-nav .active {
 @apply px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-md
}

.md-nav .inactive {
 @apply px-3 py-2 text-sm font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white
}

.s-nav .active {
  @apply block px-3 py-2 text-base font-medium text-white bg-gray-900 rounded-md
}

.s-nav .inactive {
  @apply block px-3 py-2 text-base font-medium text-gray-300 rounded-md hover:bg-gray-700 hover:text-white
}

.main-container {
 @apply px-0 pb-12 mx-auto max-w-7xl sm:px-6 lg:px-8
}

.main-card {
 @apply px-5 py-6 bg-white sm:rounded-lg shadow sm:px-6
}

.form-ll.form-wrapper form {
  @apply flex-col flex
}

.form-ll .section-header {
 @apply mb-5 
}
.form-ll .section-header h3 {
  @apply text-lg font-medium text-gray-900;
}

.form-ll p.section-description {
  @apply max-w-2xl text-sm text-gray-500
}

.form-ll .section-fields .section-field {
  @apply mb-5 sm:mb-0 sm:grid sm:grid-cols-3 sm:flex-gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5
}

.form-ll .section-fields .section-field .label {
  @apply block text-sm font-medium text-gray-700 
}

.form-ll .section-fields .section-field .field {
  @apply mt-2 sm:mt-0 sm:col-span-2 sm:mb-5
}

.form-ll .section-fields .section-field .field-with-errors > .error-text {
  @apply my-1 text-xs font-medium text-red-500
}

.input-text {
  @apply block max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm
}



.form-ll .section-fields .section-field .field .text-field-half {
  @apply block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm
}

.form-ll .section-field .field .text-field {
  @apply block w-full max-w-lg border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm
}

.form-ll .section-fields .section-field input[type=checkbox] {
  @apply rounded-md w-5 h-5 border border-gray-400 checked:bg-indigo-400 checked:border-indigo-500 
}
.form-ll .section-fields .section-field input[type=checkbox][disabled] {
  @apply bg-gray-100 checked:bg-gray-300 hover:bg-gray-100 focus:bg-gray-100 text-gray-100 border-gray-300
}
.form-ll .section-fields .section-field input[type=checkbox][disabled]:checked {
  @apply bg-gray-300 checked:bg-gray-300 hover:bg-gray-300 focus:bg-gray-300 text-gray-300
}

.member-checkout div.selected {
  @apply bg-indigo-50 border-indigo-200 z-10
}
.member-checkout div.unselected {
  @apply border-gray-200
}
.member-checkout div.selected p:first-of-type {
  @apply text-indigo-900
}
.member-checkout div.selected p {
  @apply text-indigo-700
}
.member-checkout div.selected p > span {
  @apply text-indigo-700
}
.member-checkout div.unselected p:first-of-type {
  @apply text-gray-900;
}
.member-checkout div.unselected p {
  @apply text-gray-500;
}
.member-checkout div.unselected p > span {
  @apply text-gray-500;
}

.button {
    @apply flex justify-center items-center px-4 py-2 text-sm font-medium border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-center
}

.button--primary {
    @apply bg-gray-800 hover:bg-gray-900 text-white
}

.button--commit {
    @apply bg-indigo-600 hover:bg-indigo-700 text-white
}

.button--danger {
    @apply bg-red-600 hover:bg-red-700 text-white
}

.button--primary-outline {
  @apply bg-white text-gray-700 hover:bg-gray-50 text-white border-gray-300
}

.button--commit-outline {
    @apply bg-white text-indigo-700 hover:bg-indigo-50 text-white border-indigo-700
}

.button--danger-outline {
    @apply bg-white text-red-700 hover:bg-red-50 text-white border-red-700
}

.flash-alerts { 
  .alert .alert--background {
    @apply bg-red-50
  }
  .alert .alert--icon {
    @apply text-red-400
  }
  .alert .alert--heading {
    @apply text-red-800
  }
  .alert .alert--text {
    @apply text-red-700
  }

  .success .alert--background {
    @apply bg-green-50
  }
  .success .alert--icon {
    @apply text-green-400
  }
  .success .alert--heading {
    @apply text-green-800
  }
  .success .alert--text {
    @apply text-green-700
  }

  .warning .alert--background {
    @apply bg-yellow-50
  }
  .warning .alert--icon {
    @apply text-yellow-400
  }
  .warning .alert--heading {
    @apply text-yellow-800
  }
  .warning .alert--text {
    @apply text-yellow-700
  }

  .notice .alert--background {
    @apply bg-blue-50
  }
  .notice .alert--icon {
    @apply text-blue-400
  }
  .notice .alert--heading {
    @apply text-blue-800
  }
  .notice .alert--text {
    @apply text-blue-700
  }
}
