/***** Wiggle *****/

.wiggle:hover {
    animation: wiggle 75ms infinite;
    animation-timing-function: linear;
}

@keyframes wiggle {
    0% {
        transform: translate(2px, 0);
    }
    50% {
        transform: translate(-2px, 0);
    }
    100% {
        transform: translate(2px, 0);
    }
}

/***** Shake *****/

.shake:hover {
    animation: shake 75ms infinite;
    animation-timing-function: linear;
}

@keyframes shake {
    0% {
        transform: rotate(0.5deg);
    }
    50% {
        transform: rotate(-0.5deg);
    }
    100% {
        transform: rotate(0.5deg);
    }
}

/***** Pulse *****/

.pulse:hover {
    animation: pulse 1s infinite;
    animation-timing-function: linear;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
        100% {
            transform: scale(1);
        }
    }
}

/***** Zoom *****/

.zoom:hover {
    transform: scale(1.1);
    transition: all ease 500ms;
}
